import React, { useState } from "react";
import icon from "./assets/flip.png";

const App = () => {
  const [flip, setFlip] = useState(false);


  return (
    <div className="App">
      <div className="iconContainer">
        <img src={icon} width="32" alt="icon" onClick={() => setFlip(!flip)} />
      </div>
      <main>
        {
          flip ?
            <img src={`/assets/images/${window.location.pathname.split("/")[1] ? window.location.pathname.split("/")[1] : 1}.png`} alt="slide" />
            :
            <video controls muted src={`/assets/videos/${window.location.pathname.split("/")[1] ? window.location.pathname.split("/")[1] : 1}.mp4`} />
        }
      </main>
    </div>
  );
};

export default App;
